.App {
  text-align: left;
  min-height: 89vh;
  
}

.Body {


  min-height: 100vh;
  margin: 0;
  background-color:  #414040;
  
  max-width: 100vw ;
  
}


.pageContent {
  text-align: center;

}


.pageContentLeft {
  text-align: left;
  font-size: 16px;
}

 

.pageContentSmall {
  text-align: center;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%; 
}

.pageContentSmallLeft {
  text-align: left;
  font-size: 14px;
  
  
}


.pageContentSmall2 {
  text-align: left;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.pageContentMed {
  text-align: center;
  font-size: 23px;
}

.pageContentMed2 {
  text-align: center;
  font-size: 23px;
  color: rgb(255, 255, 255);

}




.App-header {
  background-color: #d12121;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vh);
  color: white;
}

.App-link {
  color: #a8530e;
}

.emailLink {
  
  top :0;
  margin: 10px;
  font-size: 18px;
  color: white;
}


.navBarLink {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: white;
}

.navBarLinkActive {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: rgb(252, 239, 66);
 
}


.navBarTitle {
  margin: 0vmin;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: white;
  padding-right: Calc(20px);
}

.navBarBox {
  margin: 0px;
  padding-right: 3vw;
  padding-left: 3vw;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: rgb(0, 0, 0);
  background-color: #000d2b;
  padding-bottom: 6px;
  padding-top: 2px;
}

.footBox {
  margin: 0px;
  font-size: calc(4px + 1vh);
  text-align: center;
  min-height: 11vh;
  color: rgb(172, 172, 172);
  background-color:  #000000;
  
  bottom: 0;
 }


.divBox {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #000000; 
  
}

.divBox2 {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: #a5a5a5;
  background-color:  #ffffff; 
  
}




.list {
text-align: left;
list-style-position: inside;
margin-left: 1%;
margin-right: 1%;
}



.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
